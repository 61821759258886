/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from "react";
import Button from "../../../../components/misc/Button";
import Input from "../../../../components/forms/Input";
import TextArea from "../../../../components/forms/TextArea";
import AsyncComboBox from "../../../../components/forms/AsyncCombobox";
import {RegisterContext} from "../AllRegistersList";
import {EditContextBilling} from "../registerDetailsTabs/RegisterDetailsTab";
import Toggle from "../../../../components/forms/Toggle";
import {useAppSelector} from "../../../../store/hooks";
import {SelectInterface} from "../../../../interfaces/InterfacesLocal";
import Select from "../../../../components/forms/Select";
import CurrencyAmountInput from "../../../../components/forms/CurrencyAmountInput";
import DateInput from "../../../../components/forms/DateInput";

export const ShippingStep = ({onSubmitForm}: { onSubmitForm: Function }) => {
    const {
        control,
        watch,
        setCurrentStep,
        currentStep,
        setValue,
        getValues,
        isSubmit,
        setOpenPayModal,
        isFetching,
        shippingRegions
    } = useContext(RegisterContext);
    const {availableCurrencies, configurationsKey} = useAppSelector(
        (state) => state.init.business!
    );
    const {deliverymans} = useAppSelector(
        (state) => state.nomenclator!
    );

    const {defaultValues, editMode} = useContext(EditContextBilling);

    const [loadingHelper, setLoadingHelper] = useState(false);

    const shipingWatching = watch!("shippingType");
    const isPickUp = shipingWatching === "pickUp";
    const municipalityId = watch!("shipping.municipalityId");
    const [selectDelivers, setSelectDelivers] = useState<SelectInterface[]>([])
    const currencies =
        availableCurrencies.map((item) => item.code) ?? [];

    const [shippingRegionSelect, setShippingRegionSelect] = useState<SelectInterface[]>([])
    const deliveryType = configurationsKey.find((conf) => conf.key === "delivery_type")!.value
    const deliveryFixedPrice = configurationsKey.find((conf) => conf.key === "amount_delivery_fixed")!.value
    const deliveryCurrency = configurationsKey.find((conf) => conf.key === "general_cost_currency")!.value

    const defaultShippingPrice = useRef({
        amount: 0,
        codeCurrency: deliveryCurrency
    })

    useEffect(() => {
        if (editMode) {
            setValue!("shippingType", defaultValues?.pickUpInStore ? "pickUp" : "shipping");
            setValue!("shipping.countryId", defaultValues?.shipping?.country?.id)
            setValue!("shipping.provinceId", defaultValues?.shipping?.province?.id)
            setValue!("shipping.municipalityId", defaultValues?.shipping?.municipality?.id)
            setValue!("shippingRegionId", defaultValues?.shippingRegion?.id)

            defaultShippingPrice.current = {
                amount: defaultValues?.shippingPrice?.amount!,
                codeCurrency: defaultValues?.shippingPrice?.codeCurrency!
            }

        } else {
            setValue!("shippingType", "pickUp");
        }
        if (deliveryType === 'FIXED') {
            defaultShippingPrice.current = {
                amount: parseFloat(deliveryFixedPrice),
                codeCurrency: deliveryCurrency
            }
        }

    }, []);

    useEffect(() => {
        if (deliveryType === 'BYREGION') {
            if (shippingRegions) {
                const region: SelectInterface[] = shippingRegions
                    .filter(item => item.municipality.id === municipalityId)
                    .map(item => {
                        return {
                            id: item.id,
                            name: `${item.name} - ${item.price.amount}${item.price.codeCurrency}`
                        }
                    })
                setShippingRegionSelect(region)
            }
        }
    }, [shippingRegions, municipalityId]);

    //Cargar Mensajeros
    useEffect(() => {
        const newSelect: SelectInterface[] = deliverymans?.filter((item) => item.roles?.some((rol) => rol.name === "Repartidor"))
            .map((item) => {
                return {
                    id: item.id,
                    name: item.displayName
                }
            }) ?? []
        setSelectDelivers(newSelect)
    }, [deliverymans])

    return (
        <div className="grid gap-y-6 px-8">
            {/* row 1  */}
            <div>
                {/* <InlineRadio
          name="shippingType"
          data={RadioValues}
          control={control}
          defaultValue={
            editMode && defaultValues?.pickUpInStore
              ? "pickUp"
              : RadioValues[0].value
          }
        /> */}
                <Toggle
                    name="shippingType"
                    control={control}
                    title="Programar entrega"
                    changeState={(value: boolean) => {
                        if (value) {
                            setValue!("shippingType", "shipping");
                        } else {
                            setValue!("shippingType", "pickUp");
                        }
                    }}
                    defaultValue={editMode ? !defaultValues?.pickUpInStore : false}
                />
            </div>

            {/* row 2 */}
            <div className="min-h-[25rem]">
                {shipingWatching === "shipping" && (
                    <div className={'flex flex-col'}>
                        <h1 className={'font-bold text-lg text-gray-700 mb-2'}>
                            Datos del Receptor:
                        </h1>
                        <div className="flex w-full gap-x-4 pb-4">
                            <div className="flex flex-col gap-2 w-1/2">
                                <div>
                                    <Input
                                        label="Nombre del receptor (*)"
                                        name="shipping.firstName"
                                        control={control}
                                        disabled={isPickUp}
                                        type="textOnly"
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        defaultValue={editMode && defaultValues?.shipping?.firstName}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Apellido del receptor (*)"
                                        name="shipping.lastName"
                                        control={control}
                                        disabled={isPickUp}
                                        type="textOnly"
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        defaultValue={editMode && defaultValues?.shipping?.lastName}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Teléfono"
                                        name="shipping.phone"
                                        type="number"
                                        textAsNumber
                                        control={control}
                                        disabled={isPickUp}
                                        maxLength={10}
                                        defaultValue={editMode && defaultValues?.shipping?.phone}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Correo electrónico"
                                        name="shipping.email"
                                        control={control}
                                        disabled={isPickUp}
                                        defaultValue={editMode && defaultValues?.shipping?.email}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Código postal"
                                        name="shipping.postalCode"
                                        control={control}
                                        disabled={isPickUp}
                                        type="number"
                                        textAsNumber
                                        maxLength={10}
                                        defaultValue={editMode && defaultValues?.shipping?.postalCode}
                                    />
                                </div>

                                {/*<div className="pt-2">
                                    <Input
                                        label="Localidad"
                                        name="shipping.city"
                                        control={control}
                                        disabled={isPickUp}
                                        // rules={{
                                        //   required: {
                                        //     value: !isPickUp,
                                        //     message: "Este campo es requerido",
                                        //   },
                                        // }}
                                        defaultValue={editMode && defaultValues?.shipping?.city}
                                    />
                                </div>*/}
                            </div>

                            <div className="flex flex-col gap-2 w-1/2">
                                <div>
                                    <Input
                                        label="Calle principal"
                                        name="shipping.street_1"
                                        control={control}
                                        disabled={isPickUp}
                                        // rules={{
                                        //   required: {
                                        //     value: !isPickUp,
                                        //     message: "Este campo es requerido",
                                        //   },
                                        // }}
                                        defaultValue={editMode && defaultValues?.shipping?.street_1}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Calle secundaria"
                                        name="shipping.street_2"
                                        control={control}
                                        disabled={isPickUp}
                                        defaultValue={editMode && defaultValues?.shipping?.street_2}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        label="País"
                                        name="shipping.countryId"
                                        control={control}
                                        // id : cuba
                                        dataQuery={{
                                            url: "/public/countries",
                                            defaultParams: {id: 54, all_data: true},
                                        }}
                                        normalizeData={{id: "id", name: "name"}}
                                        disabled={isPickUp}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        // rules={{
                                        //   required: {
                                        //     value: !isPickUp,
                                        //     message: "Este campo es requerido",
                                        //   },
                                        // }}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        name="shipping.provinceId"
                                        label="Provincia"
                                        control={control}
                                        dataQuery={{url: "/public/provinces"}}
                                        normalizeData={{id: "id", name: "name"}}
                                        dependendValue={{
                                            countryId:
                                                watch!("shipping.countryId") ??
                                                getValues!("shipping.countryId"),
                                        }}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        disabled={isPickUp}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        name="shipping.municipalityId"
                                        label="Municipio"
                                        control={control}
                                        dataQuery={{url: "/public/municipalities"}}
                                        normalizeData={{id: "id", name: "name"}}
                                        dependendValue={{
                                            provinceId:
                                                watch!("shipping.provinceId") ??
                                                getValues!("shipping.provinceId"),
                                        }}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        disabled={isPickUp}
                                        defaultValue={
                                            editMode && defaultValues?.shipping?.municipality?.id
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <TextArea
                                label="Añadir notas"
                                name="shipping.description"
                                control={control}
                                disabled={isPickUp}
                                defaultValue={
                                    editMode && defaultValues?.shipping?.description
                                }
                            />
                        </div>
                        <div className={'flex flex-col'}>
                            <h1 className={'font-bold text-lg text-gray-700 mb-2'}>
                                Datos del Envío:
                            </h1>
                            <div className={'flex space-x-4'}>
                                <Select
                                    name={'shippingById'}
                                    className={'w-full'}
                                    label={'Mensajero'}
                                    data={selectDelivers}
                                    control={control}
                                    placeholder={'Seleccione un Mensajero'}
                                    defaultValue={editMode ? defaultValues?.shippingBy?.id : null}
                                />
                                <DateInput
                                    name={'deliveryAt'}
                                    control={control}
                                    label={"Fecha de Envío"}
                                    includeTime={true}
                                    defaultValue={editMode? defaultValues?.deliveryAt : Date.now}
                                    flexCol={true}
                                />

                            </div>

                            <div className={'flex space-x-2 mt-2'}>
                                {deliveryType === 'BYREGION' &&
                                    <Select
                                        label={'Región de envío'}
                                        className={'w-full'}
                                        name={'shippingRegionId'}
                                        data={shippingRegionSelect}
                                        defaultValue={null}
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        control={control}
                                    />
                                }
                                {deliveryType !== 'BYREGION' &&
                                    <CurrencyAmountInput
                                        name={'shippingPrice'}
                                        currencies={currencies}
                                        disabled={deliveryType === 'FIXED'}
                                        byDefault={defaultShippingPrice.current}
                                        control={control}
                                        placeholder={'Precio'}
                                        label={'Precio de envío'}
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                    />}


                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!editMode && (
                <div className="grid grid-cols-3 w-full h-fit gap-3 pt-2">
                    <Button
                        name="Atrás"
                        color="white"
                        textColor="blue-800"
                        outline
                        type="button"
                        action={() => {
                            setCurrentStep!(currentStep! - 1);
                        }}
                        full
                        disabled={currentStep === 0 ? true : false}
                    />
                    <Button
                        name="Registrar"
                        color="white"
                        textColor="blue-800"
                        outline
                        //type="submit"
                        full
                        loading={isSubmit && !loadingHelper}
                        disabled={isSubmit}
                        action={onSubmitForm && onSubmitForm}
                    />
                    <Button
                        name="Registrar y facturar"
                        color="slate-700"
                        type="submit"
                        full
                        loading={isSubmit && loadingHelper}
                        disabled={isSubmit}
                        action={() => {
                            //Action para abrir el modal de pago directa,mente
                            setOpenPayModal && setOpenPayModal(true);
                            setLoadingHelper(true);
                            onSubmitForm && onSubmitForm(true);
                        }}
                    />
                </div>
            )}

            {editMode && (
                <div className="flex w-full row-span-2 items-end justify-end">
                    <div className="grid">
                        <Button
                            name={editMode ? "Actualizar" : `Registrar`}
                            color="slate-700"
                            type="submit"
                            full
                            loading={isFetching}
                            disabled={isFetching}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
