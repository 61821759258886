import {useContext, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import Input from "../../../components/forms/Input";
import {useAppSelector} from "../../../store/hooks";
import Button from "../../../components/misc/Button";
import {DetailProductContext} from "../DetailProductContainer";
import {SelectInterface} from "../../../interfaces/InterfacesLocal";
import useServer from "../../../api/useServerMain";
import {CiBarcode} from "react-icons/ci";
import Modal from "../../../components/misc/GenericModal";
import Barcode from "react-barcode";
import MeasureInput from "../../../components/forms/MeasureInput";

const AdvancedDetails = () => {
    const {product, updateProduct, updateStockProductState} =
        useContext(DetailProductContext);
    const {productCategories, measures, salesCategories} = useAppSelector(
        (state) => state.nomenclator
    );
    const {control, handleSubmit, watch, register, getValues} = useForm();
    const {allowRoles: verifyRoles, denyRoles} = useServer();

    const [barCode, setBarCode] = useState<string | null>(null);



    const cubicMeasures: { key: string; value: string }[] = [
        {
            key: "1",
            value: "m&sup3;"
        },
        {
            key: "1000000",
            value: "cm&sup3;"
        },
    ]
    const lengthMeasures: { key: string; value: string }[] = [
        {
            key: "1",
            value: "m"
        },
        {
            key: "0.01",
            value: "cm"
        },
    ]

    const width = watch("width") ?? {
        quantity: 0,
        measure: lengthMeasures[0]
    }
    const length = watch("length") ?? {
        quantity: 0,
        measure: lengthMeasures[0]
    }
    const depth = watch("depth") ?? {
        quantity: 0,
        measure: lengthMeasures[0]
    }
    const cubaje = watch("cubaje") ?? {
        quantity: 0,
        measure: cubicMeasures[0]
    }

    const calculateCubaje = (inMetters: boolean = false) => {
        //Se multiplican por 10**7 y luego se divide para evitar errores de punto flotante
        if (inMetters)
            return 10**7 * width.quantity  * width.measure * length.quantity * length.measure * depth.quantity * depth.measure / 10 ** 7
        else
            return 10**7 * width.quantity  * width.measure * length.quantity * length.measure * depth.quantity * depth.measure * cubaje.measure / 10 ** 7

    }


    const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
        data
    ) => {
        const dataToSend = data;
        if (data.barCode === product?.barCode) {
            delete dataToSend.barCode;
        }
        if (width?.quantity !== 0 && length.quantity !== 0 && depth.quantity !== 0)
            data.cubaje = calculateCubaje(true)
        else
            data.cubaje = cubaje.quantity / cubaje.measure

        data.width = width.quantity * width.measure
        data.length = length.quantity * length.measure
        data.depth = depth.quantity * depth.measure
        data.hasDurationEdit = true;
        updateProduct && updateProduct(product?.id, data, updateStockProductState);
    };
    //------------------------------------------------------------------------

    const selectSalesCategory: SelectInterface[] = [];
    salesCategories.map((cat) =>
        selectSalesCategory.push({
            name: cat.name,
            id: cat.id,
        })
    );
    selectSalesCategory.push({
        name: "Sin categoría",
        id: null,
    });

    const selectStockCategory: SelectInterface[] = [];
    productCategories?.map((cat) =>
        selectStockCategory.push({
            name: cat.name,
            id: cat.id,
        })
    );
    selectStockCategory.push({
        name: "Sin categoría",
        id: null,
    });

    const selectMeasure: SelectInterface[] = [];
    measures.map((item) =>
        selectMeasure.push({
            id: item.code,
            name: item.value,
        })
    );

    //----------------------------------------------------------------------------------------------------

    const adminRole = verifyRoles([
        "ADMIN",
        "MANAGER_COST_PRICES",
        "PRODUCT_PROCESATOR",
    ]);

    const generateBarCode = () => {
        const code = "(01)" + String(product!.barCode); // Conversión explícita
        setBarCode(code);
    };


    return (
        <div>
            <div className="relative h-[34rem]">
                <div className="p-5 h-full border border-gray-300 rounded col-span-3 overflow-auto scrollbar-none">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full overflow-scroll h-full scrollbar-thin"
                    >
                        <div className={'grid grid-cols-2  w-full gap-y-2 gap-x-6'}>
                            <div>
                                <Input
                                    name="grossWeight"
                                    label="Peso bruto (Kg)"
                                    defaultValue={product?.grossWeight}
                                    control={control}
                                    textAsNumber
                                    disabled={!adminRole}
                                    type="number"
                                />
                            </div>
                            <div>
                                <Input
                                    name="netWeight"
                                    label="Peso neto (Kg)"
                                    defaultValue={product?.netWeight}
                                    control={control}
                                    textAsNumber
                                    disabled={!adminRole}
                                    type="number"
                                />
                            </div>
                            <div className="relative">
                                <Input
                                    name="barCode"
                                    label="Código de barra"
                                    defaultValue={product?.barCode}
                                    control={control}
                                    noSpecialCaracters
                                    disabled={!adminRole}
                                />
                                {product?.barCode && (
                                    <CiBarcode
                                        className="text-4xl text-gray-600 hover:text-gray-700 hover:cursor-pointer hover:scale-105 absolute right-2 bottom-0.5"
                                        onClick={generateBarCode}
                                    />
                                )}
                            </div>
                            <div>
                                <Input
                                    name="salesCode"
                                    label="Referencia"
                                    defaultValue={product?.salesCode}
                                    control={control}
                                    textAsNumber
                                    disabled={!adminRole}
                                    type="number"
                                />
                            </div>
                            <div>
                                <Input
                                    name="branch"
                                    label="Marca"
                                    defaultValue={product?.branch}
                                    control={control}
                                    type="text"
                                />
                            </div>
                            <div>
                                <Input
                                    name="colorName"
                                    label="Color"
                                    defaultValue={product?.colorName}
                                    control={control}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className={'mt-8 p-1'}>
                            <h2 className={'block font-medium text-gray-700 mb-3'}>
                                Medidas:
                            </h2>
                            <MeasureInput
                                name="cubaje"
                                label="Cubicaje"
                                valueDefault={{
                                    quantity: product?.cubaje ?? 0,
                                    measure: lengthMeasures[0].key

                                }}
                                control={control}
                                disabledValue={width?.quantity !== 0 || length?.quantity !== 0 || depth?.quantity !== 0}
                                value={
                                    (width?.quantity !== 0 || length?.quantity !== 0 || depth?.quantity !== 0)?
                                        calculateCubaje() : 0}
                                measures={cubicMeasures}
                            />
                            <div className={'w-full flex space-x-4 mt-3'}>
                                <div className={'w-full'}>
                                    <MeasureInput
                                        {...register("length",
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Las 3 medidas son necesarias"
                                                }
                                            })
                                        }
                                        label="Largo"
                                        valueDefault={{
                                            quantity: product?.length ?? 0,
                                            measure: lengthMeasures[0].key

                                    }}
                                        control={control}
                                        measures={lengthMeasures}
                                    />
                                </div>
                                <div className={'w-full'}>
                                    <MeasureInput
                                        {...register("width",
                                            {
                                                required: {
                                                    value: length?.quantity !== 0 || depth?.quantity !== 0,
                                                    message: "Las 3 medidas son necesarias"
                                                }
                                            })
                                        }
                                        label="Ancho"
                                        valueDefault={{
                                            quantity: product?.width ?? 0,
                                            measure: lengthMeasures[0].key

                                        }}
                                        control={control}
                                        measures={lengthMeasures}
                                    />
                                </div>
                                <div className={'w-full'}>
                                    <MeasureInput
                                        {...register("depth",
                                            {
                                                required: {
                                                    value: length?.quantity !== 0 || width?.quantity !== 0,
                                                    message: "Las 3 medidas son necesarias"
                                                }
                                            })
                                        }
                                        label="Profundidad"
                                        valueDefault={{
                                            quantity: product?.depth ?? 0,
                                            measure: lengthMeasures[0].key

                                        }}
                                        control={control}
                                        measures={lengthMeasures}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="absolute -bottom-14 right-0 flex justify-end pt-10 self-end">
                            {adminRole && denyRoles(["AUDITOR"]) && (
                                <Button name="Actualizar" color="slate-600" type="submit"/>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            {barCode && (
                <Modal state={!!barCode} close={() => setBarCode(null)}>
                    <div className="w-full flex flex-col items-center">
                        <h5 className="font-mono">{product?.name}</h5>
                        <Barcode value={barCode}/>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default AdvancedDetails;
