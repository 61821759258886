import {
    ArrowPathRoundedSquareIcon,
    ClipboardDocumentListIcon,
    CubeIcon,
    RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import ReportContainer from "../areas/stock/reports/ReportContainer";
import BillingAnalysis from "../billing/analysis/BillingAnalysis";
import EconomicCyclesAnalysis from "../economicCycles/analysis/EconomicCyclesAnalysis";
import CargoAnalisis from "../areas/stock/buy/CargoAnalisis";
import ProductionOrdersAnalysis from "../productionOrders/analysis/ProductionOrdersAnalysis";
import {AiOutlineFire} from "react-icons/ai";
import useServer from "../../api/useServerMain";

function AllReports() {
    const {allowRoles} = useServer()
    return (
        <div className="space-y-3">
            {allowRoles(["ADMIN", "ANALYSIS_REPORT"])&&
                <div>
        <span className="flex gap-2 text-gray-700 items-center font-medium text-base px-2 mb-2">
          <RectangleGroupIcon className="h-6 text-gray-500"/> Mis almacenes
        </span>
                    <ReportContainer breadcrumb={false}/>
                </div>
            }
            {allowRoles(["ADMIN", "ANALYSIS_REPORT"])&&
                <div>
        <span className="flex gap-2 text-gray-700 items-center font-medium text-base px-2 mb-2">
          <CubeIcon className="h-6 text-gray-500"/> Cargas
        </span>
                    <CargoAnalisis breadcrumb={false}/>
                </div>}
            {allowRoles(["ADMIN", "ANALYSIS_REPORT", "MANAGER_SHOP_ONLINE"])&&
                <div>
        <span className="flex gap-2 text-gray-700 items-center font-medium text-base px-2 mb-2">
          <ArrowPathRoundedSquareIcon className="h-6 text-gray-500"/> Ciclos
          económicos
        </span>
                <EconomicCyclesAnalysis breadcrumb={false}/>
            </div>}
            {allowRoles(["ADMIN", "ANALYSIS_REPORT"])&&
                <div>
        <span className="flex gap-2 text-gray-700 items-center font-medium text-base px-2 mb-2">
          <ClipboardDocumentListIcon className="h-6 text-gray-500"/>{" "}
            Facturación
        </span>
                    <BillingAnalysis breadcrumb={false}/>
                </div>}

            {allowRoles(["ADMIN", "ANALYSIS_REPORT"])&&
                <div>
        <span className="flex gap-2 text-gray-700 items-center font-medium text-base px-2 mb-2">
          <AiOutlineFire className="h-6 text-gray-500"/> Producción
        </span>
                    <ProductionOrdersAnalysis breadcrumb={false}/>
                </div>}
        </div>

    );
}

export default AllReports;
